import toast from 'react-hot-toast';
import i18n from '../i18n';
const baseURL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3001';

// Add this line at the top of the file
const SESSION_EXPIRED_EVENT = new Event('sessionExpired');

class RequestError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

export const doRequest = async (path, method, body, showToast) => {
  try {
    const response = await fetch(`${baseURL}${path}`, {
      method,
      ...body ? {
        body: JSON.stringify(body),
      } : {},
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const responseCode = response.status;
    let json;
    try {
      json = await response.json();
    } catch {
      throw new Error('Failed to parse JSON response');
    }
    if (responseCode >= 400) {
      if (response.status === 440) {
        // Dispatch the session expired event
        window.dispatchEvent(SESSION_EXPIRED_EVENT);
        throw new RequestError('Session expired', 440);
      } else {
        throw new RequestError(json.error ?? 'Something went wrong', responseCode);
      }
    }
    return json;
  } catch (error) {
    showToast && toast.error(i18n.t(error.message));
    throw error;
  }
};
