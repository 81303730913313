import { doRequest } from './doRequest';

export const validatePhoneNumber = async (formData, language, environment) => {
  const maskedFields = formData?.vehicle?.maskedFields ?? [];
  const defaultCountryCode = environment?.communication.defaultPhoneCode ?? '+32';
  const mobileMasked = maskedFields.includes('driver_mob_no');
  // if phone is masked, send null to server
  // server will pull correct phone number from session
  const phoneNumber = mobileMasked ? null : formData.vehicle.driver_mob_no;
  try {
    const response = await doRequest(
      '/validate-number',
      'POST',
      {
        countryCode: formData.mobile_country_code ?? defaultCountryCode,
        destination: phoneNumber,
        language,
      },
      true
    );
    if (response.alreadyValidated) {
      return { alreadyValidated: true };
    }
    return { messageSent: true };
  } catch {
    return false;
  }
};

export const verifyValidationCode = async (code) => {
  try {
    await doRequest(
      '/verify-validation-code',
      'POST',
      {
        code,
      },
      true
    );
    return true;
  } catch {
    return false;
  }
};