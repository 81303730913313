import React, { useState } from 'react';
import { Form, Row, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage } from '../../context/ContextProvider';
import submitBooking from '../../handlers/submit-booking';
import '../Styles/Summary.scss';

function Summary() {
  const { formData, handleInputChange, resetForm } = useForm();
  const [loading, setLoading] = useState(false);
  const { nextPage, prevPage, goToPage } = usePage();
  const { t, i18n } = useTranslation();
  const { language } = i18n ?? {};

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await submitBooking(formData);
      resetForm();
      nextPage();
    } catch (e) {
      console.error(e.message);
    }
    setLoading(false);
  };

  const handlePreviousPage = () => {
    const { selectedService } = formData;
    // in case of contact form, skip date picker page
    if (selectedService.isContact) {
      goToPage(-2);
    } else {
      prevPage();
    }
  };

  const driverData = (
    <>
      <tr>
        <td className="text-muted">{t('driverName')}</td>
        <td>{formData.vehicle.driver_name}</td>
      </tr>
      <tr>
        <td className="text-muted">{t('driverMobile')}</td>
        <td>{formData.vehicle.driver_email}</td>
      </tr>
      <tr>
        <td className="text-muted">{t('driverEmail')}</td>
        <td>{formData.vehicle.driver_mob_no}</td>
      </tr>
    </>
  );

  const vehicleData = (
    <>
      <tr>
        <td width="155" className="text-muted">{t('licensePlate')}</td>
        <td>{formData.vehicle.license_plate}</td>
      </tr>
      <tr>
        <td className="text-muted">{t('summary.vehicle')}</td>
        <td>{`${formData.vehicle.brand} ${formData.vehicle.description}`}</td>
      </tr>
      <tr>
        <td className="text-muted">{t('vehicleDetails.chassisNumber')}</td>
        <td>{formData.vehicle.chassis_number}</td>
      </tr>
      <tr>
        <td className="text-muted">{t('tyreDetails.tyres')}</td>
        <td>{formData.tyreInput.frontTyres}</td>
      </tr>
      {formData.tyreInput.backTyresDifferent && (
        <tr>
          <td className="text-muted">{t('tyreDetails.backTyres')}</td>
          <td>{formData.tyreInput.backTyres}</td>
        </tr>
      )}
    </>
  );

  const customerData = formData.customer.type !== 'owner' ? (
    <>

      <tr>
        <td className="text-muted">{t('summary.customer')}</td>
        <td>{`${formData.customer.name} (${formData.customer.type === 'leasing' ? t('summary.leasing') : t('summary.partner')})`}</td>
      </tr>
    </>
  ) : null;

  // service and picked date
  const agendaData = (
    <>
      <tr>
        <td className="text-muted">{t('summary.selectedService')}</td>
        <td>{formData.selectedService.label.find((l => l.language === (language ?? 'en'))).value}</td>
      </tr>
      {formData.additionalServices.length > 0 && (
        <>
          <tr>
            <td className="text-muted">{t('summary.additionalServices')}</td>
            <td>
              { formData.additionalServices.map(
                service => service.label.find((l => l.language === (language ?? 'en'))).value
              ).join(', ') }
            </td>
          </tr>
        </>
      )}
      {formData.selectedSlot && (
        <tr>
          <td className="text-muted">{t('summary.selectedSlot')}</td>
          <td>{dayjs(formData.selectedSlot.key, 'YYYYMMDDHHmm').format('DD/MM/YYYY - HH:mm')}</td>
        </tr>
      )}
    </>
  );

  return (
    <>
      <PageHeader title={t('summary.title')} subtitle={t('summary.subtitle')} />
      <Form onSubmit={handleSubmit}>
        <Table size="sm" className="responsive-table">
          <tbody>
            {driverData}
            {vehicleData}
            {customerData}
            {agendaData}
          </tbody>
        </Table>
        <Row className="text-center">
          <Col>
            <Form.Label htmlFor="comment"><b>{t('summary.comments')}</b></Form.Label>
            <Form.Control id="comment" as="textarea" className="text-center" onChange={handleInputChange} name="comment" placeholder={t('summary.comments')} rows={3} />
          </Col>
        </Row>
        <PageControls loading={loading} prevHandler={handlePreviousPage} />
      </Form>
    </>
  );
}

export default Summary;