import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useEnvironment } from '../../context/ContextProvider';

function LeasingSelect({ value, onChange, name }) {
  const { environment } = useEnvironment();
  const { leasingCompanies } = environment ?? {};
  if (!leasingCompanies) return null;

  const leasingOptions = leasingCompanies.map((lc) => {
    return {
      value: lc.ticsCode,
      label: lc.name,
    };
  });

  const { t } = useTranslation();
  const defaultSelected = value ? leasingOptions.find((o) => o.value === value) ?? undefined : undefined;

  return (
    <Select
      className="react-select-container text-center"
      classNamePrefix="react-select"
      required
      options={leasingOptions}
      onChange={(selected) => onChange({
        target: {
          value: selected.value,
          name,
        },
      })}
      maxMenuHeight={165}
      isSearchable
      value={defaultSelected}
      placeholder={t('leasingSelect.placeholder')}
      noOptionsMessage={() => t('leasingSelect.emptyLabel')}
    />
  );
}

LeasingSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default LeasingSelect;