import React, { useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage } from '../../context/ContextProvider';
import { getTyres } from '../../handlers/webstreamApiCalls';

function TyreDetails() {
  const { formData, setFormData, handleInputChange } = useForm();
  const { nextPage, prevPage } = usePage();
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      const tyres = await getTyres();
      if (tyres.length) {
        const tyreDescriptions = tyres.map((tyre) => tyre.description);
        const frontTyres = tyreDescriptions[0];
        let backTyresDifferent = false;
        let backTyres;
        // front tyres have different descriptions than back tyres
        if (tyreDescriptions[0] !== tyreDescriptions[2]) {
          backTyresDifferent = true;
          backTyres = tyreDescriptions[2];
        }
        setFormData({
          ...formData,
          tyreInput: {
            frontTyres,
            backTyresDifferent,
            backTyres,
          }
        });
      }
    })();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    nextPage();
  };

  return (
    <>
      <PageHeader title={t('tyreDetails.title')} subtitle={t('tyreDetails.subtitle')} />
      <Form onSubmit={handleSubmit}>
        <Row className="text-center mt-2">
          <Col>
            <Form.Label>{t('tyreDetails.tyres')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="tyreInput.frontTyres"
              className="text-center"
              type="text"
              placeholder={t('vehicleDescriptionPlaceholder')}
              value={formData?.tyreInput?.frontTyres}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        {formData?.tyreInput?.backTyresDifferent && (
          <Row className="text-center mt-2">
            <Col>
              <Form.Label>{t('tyreDetails.backTyres')}</Form.Label>
              <Form.Control
                required
                autoComplete="off"
                name="tyreInput.backTyres"
                className="text-center"
                type="text"
                placeholder={t('vehicleDescriptionPlaceholder')}
                value={formData?.tyreInput?.backTyres}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        )}
        <Form.Group className="d-flex justify-content-center mt-2">
          <Form.Check
            type="checkbox"
            id="backTyresDifferent"
            name="tyreInput.backTyresDifferent"
            checked={formData?.tyreInput?.backTyresDifferent}
            onChange={handleInputChange}
            label={t('tyreDetails.backTyresDifferent')}
          />
        </Form.Group>
        {/*
        <Row className="text-center mt-2">
          <Col>
            <Form.Label>{t('tyreDetails.comments')}</Form.Label>
            <Form.Control
              className="text-center"
              as="textarea"
              rows={3}
              onChange={handleInputChange}
              placeholder={t('tyreDetails.commentsPlaceholder')}
              name="tyreInput.comments"
            />
          </Col>
        </Row>
        */}
        <PageControls prevHandler={prevPage} />
      </Form>
    </>
  );
}

export default TyreDetails;