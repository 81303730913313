import React from 'react';
import { PageHeader } from './shared';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import { usePage } from '../../context/ContextProvider';

function SuccessMessage() {
  const { t } = useTranslation();
  const { goToPage } = usePage();
  return (
    <div className="text-center">
      <PageHeader title={t('successMessage.title')} subtitle={t('successMessage.subtitle')} />
      <FontAwesomeIcon size="4x" icon={faCheckCircle} className="text-primary" />
      <p className="mt-3">{t('successMessage.description')}</p>
      <Button onClick={() => {
        goToPage(0);
      }}>
        <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
        {t('successMessage.goToStart')}
      </Button>
    </div>
  );
}

export default SuccessMessage;