import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage } from '../../context/ContextProvider';
import { licensePlateLookup } from '../../handlers/webstreamApiCalls';
function LicenseplateLookup() {
  const { formData, setFormData } = useForm();
  const { nextPage, setPageError } = usePage();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const vehicleData = await licensePlateLookup(formData.vehicle.license_plate);
      const vehicle = vehicleData.vehicle ?? {};
      setFormData({ ...formData, vehicle });
      nextPage();
    } catch (error) {
      if (error.code === 404) {
        // Proceed to the next page with an empty vehicle object
        setFormData({ ...formData, vehicle: { license_plate: formData.vehicle.license_plate } });
        nextPage();
      } else if (error.code === 403) {
        // Handle multiple references error
        setPageError('error.multipleReferences');
      } else {
        // Handle other errors
        setLoading(false);
        setPageError('error.licensePlateLookup');
      }
    }
  };

  const capitalizeAndChange = (e) => {
    e.preventDefault();
    const licensePlate = e.target.value;
    // capitalize & remove spaces
    const capitalizedLicensePlate = licensePlate.toUpperCase().replace(/\s/g, '');
    setFormData({ ...formData, vehicle: { ...formData.vehicle, license_plate: capitalizedLicensePlate } });
  };

  return (
    <>
      <PageHeader title={t('licensePlateLookup.title')} subtitle={t('licensePlateLookup.subtitle')} />
      <Form onSubmit={submitForm}>
        <Row className="text-center">
          <Col>
            <Form.Label>{t('licensePlate')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="vehicle.license_plate"
              className="text-center"
              size="lg"
              type="text"
              placeholder={t('licensePlatePlaceholder')}
              value={formData.vehicle?.license_plate}
              onChange={capitalizeAndChange}
            />
            <PageControls loading={loading} drawPreviousButton={false} />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default LicenseplateLookup;