import { doRequest } from './doRequest';

export const getEnvironment = async () => {
  // fetch environment data for current URL
  const currentUrl = window.location.href;
  const environment = await doRequest(`/agenda-config?url=${currentUrl}`, 'GET');
  return environment;
};

export const setSessionLanguage = async (language) => {
  await doRequest('/language', 'POST', { language });
};