import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CountryCodes from '../../constants/country-codes';
import { useTranslation } from 'react-i18next';

const selectOptions = CountryCodes.map(option => {
  return {
    value: option,
    label: option,
  };
});

function CountryCodeSelect({ value, onChange, name }) {
  const { t } = useTranslation();
  const defaultSelected = value ? selectOptions.find((o) => o.value === value) ?? undefined : undefined;
  return (
    <Select
      classNamePrefix="react-select"
      maxMenuHeight={125}
      value={defaultSelected}
      options={ selectOptions }
      required
      placeholder={t('countryCodeSelect.placeholder')}
      noOptionsMessage={() => t('countryCodeSelect.emptyLabel')}
      isSearchable
      onChange={(selected) => onChange({
        target: {
          value: selected.value,
          name,
        },
      })}
    />
  );
}

CountryCodeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default CountryCodeSelect;