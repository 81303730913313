import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage } from '../../context/ContextProvider';
import { getServices } from '../../handlers/serviceHandler';

function ServicePicker() {
  const { formData, setFormData, clearAgendaData } = useForm();
  const { nextPage, prevPage, goToPage, currentPage } = usePage();
  const [services, setServices] = useState([]);
  const { t, i18n } = useTranslation();

  // keep track of initial service on page load
  const initialService = useRef(formData.selectedService);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.selectedService) {
      toast.error(t('servicePicker.selectServiceFirst'));
      return;
    }
    // if service has changed, reset all datepicker sensitive data
    if (formData.selectedService?._id !== initialService.current?._id) {
      console.log('Service changed, resetting agenda slots');
      clearAgendaData();
    }
    if (formData.selectedService.isContact) {
      // skip date picker page for contact services
      goToPage(currentPage + 2);
      return;
    }
    nextPage();
  };
  const { language } = i18n ?? {};
  const isFirstRender = useRef(true);
  // load services on initial page load
  useEffect(() => {
    if (isFirstRender.current) {
      getServices().then((services) => {
        setServices(services);
      });
      isFirstRender.current = false;
    }
  }, []);

  const serviceButton = (service, index) => {
    const label = service.label.find((label) => label.language === language)?.value;
    const description = service.description.find((desc) => desc.language === language)?.value;
    const isSelected = formData.selectedService?._id === service._id;

    // ensure that last service is full width in case of uneven number of services
    const isLast = index === services.length - 1;
    const isUneven = services.length % 2 !== 0;
    const colSize = isLast && isUneven ? 12 : 6;

    return (
      <Col sm={12} lg={colSize} key={service._id}>
        <Button
          className="service-button"
          onClick={() => setFormData({
            ...formData,
            selectedService: service,
            additionalServices: [],
          })}
          variant={isSelected ? 'primary' : 'outline-primary'}
        >
          <p className="mb-0"><b>{label}</b></p>
          {description && <small>{description}</small>}
        </Button>
      </Col>
    );
  };

  const toggleAdditionalService = (service) => {
    if (formData.additionalServices?.map(s => s._id).includes(service._id)) {
      setFormData({ ...formData, additionalServices: formData.additionalServices.filter(s => s._id !== service._id) });
    } else {
      setFormData({ ...formData, additionalServices: [...formData.additionalServices ?? [], service] });
    }
  };

  const additionalServiceCheckbox = (service) => {
    const label = service.label.find((label) => label.language === language)?.value;
    const isSelected = formData.additionalServices?.map(s => s._id).includes(service._id);

    return (
      <Col xs={12} key={service._id}>
        <Form.Group className="d-flex justify-content-center">
          <Form.Check
            type="checkbox"
            id={`checkbox-${service._id}`}
            label={label}
            checked={isSelected}
            onChange={() => toggleAdditionalService(service)}
          />
        </Form.Group>
      </Col>
    );
  };

  const additionalServicesSegment = () => {
    if (!formData.selectedService?.additionalServices) return null;
    return (
      <div className="text-center">
        <hr />
        <label className="mb-2">{t('servicePicker.additionalServices')}</label>
        <Row className="justify-content-center g-2">
          {formData.selectedService.additionalServices.map(additionalServiceCheckbox)}
        </Row>
      </div>
    );
  };

  return (
    <>
      <PageHeader title={t('servicePicker.title')} subtitle={t('servicePicker.subtitle')} />
      <Form onSubmit={handleSubmit}>
        <Row className="text-center g-2">
          {services?.map(serviceButton)}
        </Row>
        {formData.selectedService?.additionalServices?.length ? additionalServicesSegment() : null}
        <hr />
        <PageControls prevHandler={prevPage} />
      </Form>
    </>
  );
}

export default ServicePicker;