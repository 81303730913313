import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { PageHeader, PageControls } from './shared';
import { VehicleBrandSelect } from '../Controls';
import { useForm, usePage } from '../../context/ContextProvider';
import vinValidator from '../../validators/vin-number';

function VehicleDetails() {
  const { t } = useTranslation();
  const { nextPage, prevPage, goToPage } = usePage();
  const { formData, handleInputChange } = useForm();

  const mobileAlreadyValidated = formData?.mobile_verified;

  // because previous page is the mobile validation page
  // we should go back 2 pages if number is already validated
  const prevPageInterceptor = () => {
    if (mobileAlreadyValidated) {
      goToPage(-2);
    } else {
      prevPage();
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const vin = formData?.vehicle.chassis_number;
    if (!vinValidator(vin)) {
      toast.error(t('vehicleDetails.invalidVin'));
    } else {
      nextPage();
    }
  };

  return (
    <>
      <PageHeader title={t('vehicleDetails.title')} subtitle={t('vehicleDetails.subtitle')} />
      <Form onSubmit={onSubmit}>
        <Row className="text-center">
          <Col>
            <Form.Label>{t('vehicleDetails.brand')}</Form.Label>
            <VehicleBrandSelect
              value={formData?.vehicle.brand}
              name="vehicle.brand"
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="text-center mt-2">
          <Col>
            <Form.Label>{t('vehicleDetails.description')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="vehicle.description"
              className="text-center"
              type="text"
              placeholder={t('vehicleDescriptionPlaceholder')}
              value={formData?.vehicle.description}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <Row className="text-center mt-2">
          <Col>
            <Form.Label>{t('vehicleDetails.chassisNumber')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="vehicle.chassis_number"
              className="text-center"
              type="text"
              minLength={17}
              maxLength={17}
              placeholder={t('vehicleDescriptionPlaceholder')}
              value={formData?.vehicle.chassis_number}
              onChange={handleInputChange}
            />
          </Col>
        </Row>
        <PageControls prevHandler={prevPageInterceptor} />
      </Form>
    </>
  );
}

export default VehicleDetails;