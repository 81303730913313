// EnvironmentContext.js
import React, {
  useContext, createContext, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { getEnvironment } from '../handlers/environment';

const EnvironmentContext = createContext();
const FormContext = createContext();
const PageContext = createContext();

export const useEnvironment = () => useContext(EnvironmentContext);
export const useForm = () => useContext(FormContext);
export const usePage = () => useContext(PageContext);

export function ContextProvider({ children }) {
  const [environment, setEnvironment] = useState(null);
  const [formData, setFormData] = useState({});

  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      getEnvironment().then((environmentData) => {
        setEnvironment(environmentData);
        // update document title
        document.title = environmentData.name + ' - Agenda';
      }).catch((error) => {
        console.error('Error fetching environment:', error);
        setEnvironment(undefined);
      });
      isMounted.current = true; // Mark as mounted
    }
  }, []);

  const resetEnvironment = () => {
    getEnvironment().then((environmentData) => {
      setEnvironment(environmentData);
    });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    setFormData((prevFormData) => {
      // Split the name by dots to handle nested fields
      const keys = name.split('.');
      const updatedFormData = { ...prevFormData };

      // based on type of input, we might need to set the value differently
      let inputValue;
      if (type === 'checkbox') {
        inputValue = checked;
      } else {
        inputValue = value;
      }

      // Iterate through keys and create nested structure
      keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
          acc[key] = inputValue;
        } else {
          acc[key] = acc[key] || {};
        }
        return acc[key];
      }, updatedFormData);

      return updatedFormData;
    });
  };

  // flag mobile number as not verified
  const flagMobileUnverified = () => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData.mobile_verified = false;
      return updatedFormData;
    });
  };

  // flag mobile number as verified
  const flagMobileVerified = () => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData.mobile_verified = true;
      return updatedFormData;
    });
  };

  // clear agenda data after sensitive data changes
  const clearAgendaData = () => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      updatedFormData.agendaSlots = [];
      updatedFormData.availableDates = [];
      updatedFormData.fetchedDates = [];
      updatedFormData.selectedSlot = null;
      return updatedFormData;
    });
  };

  const resetForm = () => {
    setFormData({});
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [pageError, setPageError] = useState(null);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToPage = (page) => {
    if (page < 0) {
      // do countback
      setCurrentPage(currentPage + page);
    } else {
      setCurrentPage(page);
    }
  };

  const pageContextValue = {
    currentPage,
    goToPage,
    nextPage,
    prevPage,
    pageError,
    setPageError,
  };

  const formContextValue = {
    formData,
    setFormData,
    handleInputChange,
    flagMobileUnverified,
    flagMobileVerified,
    clearAgendaData,
    resetForm,
  };

  const environmentContextValue = {
    environment,
    resetEnvironment,
  };

  return (
    <EnvironmentContext.Provider value={environmentContextValue}>
      <PageContext.Provider value={pageContextValue}>
        <FormContext.Provider value={formContextValue}>
          {children}
        </FormContext.Provider>
      </PageContext.Provider>
    </EnvironmentContext.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.node,
};