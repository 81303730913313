export default [
  '+30',
  '+31',
  '+32',
  '+33',
  '+34',
  '+350',
  '+351',
  '+352',
  '+353',
  '+354',
  '+355',
  '+356',
  '+357',
  '+358',
  '+359',
  '+36',
  '+370',
  '+371',
  '+372',
  '+373',
  '+374',
  '+375',
  '+376',
  '+377',
  '+378',
  '+379',
  '+380',
  '+381',
  '+385',
  '+386',
  '+387',
  '+389',
  '+39',
  '+3906',
  '+40',
  '+41',
  '+42',
  '+420',
  '+421',
  '+422',
  '+423',
  '+43',
  '+44',
  '+45',
  '+46',
  '+47',
  '+48',
  '+49',
];