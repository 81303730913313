import dayjs from 'dayjs';
import { doRequest } from './doRequest';

export const getSchedule = async (serviceId, startDate, endDate) => {
  // omitting date will fetch current date
  let requestDate;
  let requestEndDate;
  if (startDate) {
    requestDate = dayjs(startDate).format('YYYY-MM-DD');
  } else {
    requestDate = dayjs().format('YYYY-MM-DD');
  }
  if (endDate) {
    requestEndDate = dayjs(endDate).format('YYYY-MM-DD');
  }

  const queryParams = {
    'start-date': requestDate,
    ...requestEndDate && { 'end-date': requestEndDate },
    serviceId: serviceId,
  };

  const response = await doRequest(`/agenda-schedule?${new URLSearchParams(queryParams).toString()}`, 'GET', null, true);
  return response;
};