import React from 'react';
import PropTypes from 'prop-types';

function PageHeader({ title, subtitle }) {
  return (
    <div className="text-center">
      <b>{title}</b>
      <p>{subtitle}</p>
      <hr />
    </div>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageHeader;