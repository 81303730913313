import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function PreviousButton({ onClick, icon, className, loading }) {
  const { t } = useTranslation();
  return (
    <Button type="button" disabled={loading} onClick={onClick} className={`nav-button ${className}`} variant="secondary">
      {icon && (
        <>
          {icon}
          &nbsp;
        </>
      )}
      {t('back')}
    </Button>
  );
}

PreviousButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default PreviousButton;