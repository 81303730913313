import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { PreviousButton, NextButton } from '../../Controls';

const PageControls = ({
  className,
  nextIcon,
  prevIcon,
  nextHandler,
  prevHandler,
  loading,
  drawPreviousButton = true,
}) => {
  return (
    <Row className={`${className ?? 'mt-3 g-1'}`}>
      {drawPreviousButton &&
        <Col xs={5} md={4}>
          <PreviousButton loading={loading} onClick={prevHandler} icon={<FontAwesomeIcon icon={prevIcon ?? faChevronLeft} />}  />
        </Col>
      }
      <Col xs={drawPreviousButton ? 7 : 12} md={ drawPreviousButton ? 8 : 12}>
        <NextButton loading={loading} onClick={nextHandler} icon={<FontAwesomeIcon icon={nextIcon ?? faCheck} />}  />
      </Col>
    </Row>
  );
};

PageControls.propTypes = {
  className: PropTypes.string,
  nextIcon: PropTypes.object,
  prevIcon: PropTypes.object,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  loading: PropTypes.bool,
  drawPreviousButton: PropTypes.bool,
};

export default PageControls;