import { doRequest } from './doRequest';

export const licensePlateLookup = async (licensePlate) => {
  const response = await doRequest(`/license-plate-lookup?license_plate=${licensePlate}`, 'GET');
  return response;
};

export const getTyres = async () => {
  const response = await doRequest('/tyres', 'GET');
  return response;
};