import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, useEnvironment, usePage } from '../../context/ContextProvider';
import { validatePhoneNumber, verifyValidationCode } from '../../handlers/mobileValidation';

const RETRY_TIMER = 60; // in seconds
function MobileVerification() {
  const { environment } = useEnvironment();
  const { nextPage, prevPage } = usePage();
  const { formData, flagMobileVerified } = useForm();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(RETRY_TIMER);
  const { t, i18n } = useTranslation();
  const { language } = i18n ?? {};

  // if mobile already verified, go to next page
  const mobileAlreadyValidated = formData?.mobile_verified;
  useEffect(() => {
    if (mobileAlreadyValidated) {
      nextPage();
    }
  });

  const timerRef = useRef(null); // Reference to hold the timer

  const startTimer = () => {
    clearInterval(timerRef.current); // Clear any existing timer
    setSecondsRemaining(RETRY_TIMER);
    timerRef.current = setInterval(() => {
      setSecondsRemaining((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
  };

  useEffect(() => {
    startTimer(); // Start the timer on component mount
    return () => clearInterval(timerRef.current); // Clear the timer on component unmount
  }, []);

  const resendValidationCode = async () => {
    setLoading(true);
    const success = await validatePhoneNumber(formData, language, environment);
    if (success) {
      startTimer();
    }
    setLoading(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const phoneVerified = await verifyValidationCode(code);
    if (phoneVerified) {
      flagMobileVerified();
      nextPage();
    }
    setLoading(false);
  };

  return (
    <>
      <PageHeader title={t('mobileValidation.title')} subtitle={t('mobileValidation.subtitle')} />
      <Form onSubmit={onSubmit}>
        <Row className="text-center mt-3">
          <Col>
            <Form.Label>{t('mobileValidation.code')}</Form.Label>
            <Form.Control
              required
              autoComplete="off"
              name="mobile_validation_code"
              className="text-center"
              size="lg"
              type="text"
              value={code}
              onChange={(event) => setCode(event.target.value)}
              placeholder={t('mobileValidation.codePlaceholder')}
            />
            <small>{t('mobileValidation.help')}</small>
          </Col>
        </Row>
        <Row className="text-center mt-2">
          <Col>
            <Button
              disabled={loading || secondsRemaining > 0}
              variant="link"
              onClick={resendValidationCode}
            >
              {secondsRemaining > 0
                ? t('mobileValidation.resendCodeIn', { secondsRemaining })
                : t('mobileValidation.resendCode')
              }
            </Button>
          </Col>
        </Row>
        <PageControls prevHandler={prevPage} loading={loading} />
      </Form>
    </>
  );
}

export default MobileVerification;