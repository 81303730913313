import React, { useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageHeader, PageControls } from './shared';
import { useForm, usePage } from '../../context/ContextProvider';
import { LeasingSelect } from '../Controls';
import { savePartnerPreferences, saveLeasingPreferences, clearCustomerPreferences } from '../../handlers/customerHandler';

function CustomerDetails() {
  const { formData, setFormData, handleInputChange, clearAgendaData } = useForm();
  const { nextPage, prevPage } = usePage();
  const { t } = useTranslation();

  const handleCustomerOptionChange = (event) => {
    const { value } = event.target;
    const { customer } = formData;
    customer.type = value;
    if (value === 'owner') {
      delete customer.leasingCompany;
      delete customer.partnerCode;
      delete customer.name;
    } else if (value === 'leasing') {
      delete customer.partnerCode;
      delete customer.name;
    } else if (value === 'partner') {
      delete customer.leasingCompany;
      delete customer.name;
    }
    setFormData({ ...formData, customer });
  };

  const initialCustomer = useRef(null);

  useEffect(() => {
    // Initialize initialCustomer only once on component mount
    initialCustomer.current = JSON.parse(JSON.stringify(formData.customer ?? {}));
  }, []);

  useEffect(() => {
    // if no customer yet set, set it to type 'owner'
    if (!formData.customer) {
      setFormData({ ...formData, customer: { type: 'owner' } });
    }
  }, [formData, setFormData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formData.customer?.type === 'leasing') {
        const { name } = await saveLeasingPreferences(formData.customer.leasingCompany);
        setFormData({ ...formData, customer: { ...formData.customer, name } });
      } else if (formData.customer?.type === 'partner') {
        const { name } = await savePartnerPreferences(formData.customer.partnerCode);
        setFormData({ ...formData, customer: { ...formData.customer, name } });
      } else {
        await clearCustomerPreferences();
      }
      if (initialCustomer.current.name !== formData.customer.name) {
        console.log('Customer changed, resetting agenda');
        clearAgendaData();
        // clear selectedService & additional services
        setFormData({ ...formData, selectedService: null, additionalServices: [] });
      }
      nextPage();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <PageHeader title={t('customerDetails.title')} subtitle={t('customerDetails.subtitle')} />
      <Form onSubmit={handleSubmit}>
        <Row className="text-center mt-3">
          <Col>
            <Form.Group className="d-flex justify-content-center">
              <Form.Check
                type="radio"
                id="customer-type-owner"
                className="customer-radio"
                name="customer.type"
                value="owner"
                checked={formData?.customer?.type === 'owner' || !formData?.customer}
                onChange={handleCustomerOptionChange}
                label={t('customerDetails.ownerOption')}
              />
            </Form.Group>
            <small className="text-muted">{t('customerDetails.ownerOptionDescription')}</small>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <Form.Group className="d-flex justify-content-center">
              <Form.Check
                type="radio"
                id="customer-type-leasing"
                className="customer-radio"
                name="customer.type"
                value="leasing"
                checked={formData?.customer?.type === 'leasing'}
                onChange={handleCustomerOptionChange}
                label={t('customerDetails.leasingOption')}
              />
            </Form.Group>
            <small className="text-muted">{t('customerDetails.leasingOptionDescription')}</small>
          </Col>
        </Row>
        {formData.customer?.type === 'leasing' && (
          <LeasingSelect
            value={formData?.customer?.leasingCompany}
            onChange={handleInputChange}
            name="customer.leasingCompany"
          />
        )}
        <Row className="text-center mt-3">
          <Col>
            <Form.Group className="d-flex justify-content-center">
              <Form.Check
                type="radio"
                id="customer-type-partner"
                className="customer-radio"
                name="customer.type"
                value="partner"
                checked={formData?.customer?.type === 'partner'}
                onChange={handleCustomerOptionChange}
                label={t('customerDetails.partnerOption')}
              />
            </Form.Group>
            <small className="text-muted">{t('customerDetails.partnerOptionDescription')}</small>
          </Col>
        </Row>
        {formData.customer?.type === 'partner' && (
          <Form.Control
            required
            autoComplete="off"
            name="customer.partnerCode"
            className="text-center"
            type="text"
            placeholder={t('customerDetails.partnerCodePlaceholder')}
            value={formData?.customer?.partnerCode}
            onChange={handleInputChange}
          />
        )}
        <PageControls className="mt-5 g-1" prevHandler={prevPage} />
      </Form>
    </>
  );
}

export default CustomerDetails;