import React from 'react';
import PropTypes from 'prop-types';
import VehicleBrands from '../../constants/vehicle-brands';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const brandOptions = VehicleBrands.map(option => {
  return {
    value: option,
    label: option,
  };
});

function VehicleBrandSelect({ value, onChange, name }) {
  const { t  } = useTranslation();
  const selectOptions = [
    ...brandOptions,
    { value: t('unknownBrand'), label: t('unknownBrand') },
  ];
  let defaultSelected;
  if (value) {
    defaultSelected = selectOptions.find(option => option.value.toUpperCase() === value.toUpperCase());
    if (!defaultSelected) {
      defaultSelected = selectOptions[0];
    }
  }
  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      required
      options={selectOptions}
      onChange={(selected) => onChange({
        target: {
          value: selected.value,
          name,
        },
      })}
      maxMenuHeight={165}
      isSearchable
      value={defaultSelected}
      placeholder={t('vehicleBrandSelect.placeholder')}
      noOptionsMessage={() =>t('vehicleBrandSelect.emptyLabel')}
    />
  );
}

VehicleBrandSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default VehicleBrandSelect;