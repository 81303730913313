import { doRequest } from './doRequest';

export const savePartnerPreferences = async (code) => {
  const response = await doRequest('/partner-preferences', 'POST', { code }, true);
  return response;
};

export const saveLeasingPreferences = async (leaseCo) => {
  const response = await doRequest('/leasing-preferences', 'POST', { leaseCo }, true);
  return response;
};

export const clearCustomerPreferences = async () => {
  const response = await doRequest('/clear-customer-preferences', 'POST', {}, true);
  return response;
};