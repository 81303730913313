import React from 'react';
import ContentWrapper from './components/ContentWrapper';
import './i18n'; // Import i18n configuration

import { ContextProvider } from './context/ContextProvider';

function App() {
  return (
    <ContextProvider>
      <ContentWrapper />
    </ContextProvider>
  );
}

export default App;
