import { doRequest } from './doRequest';

export default async (formData) => {
  const body = {
    service: formData.selectedService._id,
    additionalServices: formData.additionalServices?.map(service => service._id),
    slot: formData.selectedSlot,
    tyreInput: formData.tyreInput,
    comment: formData.comment,
    vehicle: formData.vehicle,
  };
  const response = await doRequest('/book', 'POST', body, true);
  return response;
};