// this function takes an array of agendaslots, groups them per columnId and returns all possible
// combinations of slots that fit within the target duration
export default function (dataset, targetDuration) {
  // Sort the dataset by date, column, and start time
  dataset.sort((a, b) => {
    if (a.date !== b.date) return a.date.localeCompare(b.date);
    if (a.column !== b.column) return a.column.localeCompare(b.column);
    return a.start.localeCompare(b.start);
  });

  // Loop through each entry as a potential starting point
  for (let i = 0; i < dataset.length; i++) {
    const currentSlot = { ...dataset[i] };
    let accumulatedDuration = currentSlot.duration;
    const groupedSlots = [currentSlot];

    // Immediately store the slot if its duration is sufficient
    if (accumulatedDuration >= targetDuration) {
      dataset[i].groupedSlots = groupedSlots;
      dataset[i].totalDuration = accumulatedDuration;
      continue; // Skip to the next slot in the dataset
    }

    // keep track of last slot in sequence for comparisons
    let lastSlotInSequence = currentSlot;

    // Check subsequent slots to accumulate the target duration
    for (let j = i + 1; j < dataset.length; j++) {
      const nextSlot = dataset[j];
      // Check if the next slot is consecutive and in the same column
      if (
        nextSlot.start === lastSlotInSequence.end &&
        nextSlot.column === lastSlotInSequence.column &&
        nextSlot.date === lastSlotInSequence.date
      ) {
        accumulatedDuration += nextSlot.duration;
        groupedSlots.push(nextSlot);
        lastSlotInSequence = nextSlot;

        // Check if we've hit the target duration
        if (accumulatedDuration >= targetDuration) {
          dataset[i].groupedSlots = groupedSlots;
          dataset[i].totalDuration = accumulatedDuration;
          break; // Stop checking further slots for this starting point
        }
      } else {
        break; // Stop if we encounter a non-consecutive slot
      }
    }
  }

  const slotsWithGroups = dataset.filter(entry => entry.groupedSlots);
  // only return one datetime entry (in case multiple columns)
  const usedDateTimes = new Set();
  const results = slotsWithGroups.map((slot) => {
    const dateTime = slot.date + slot.start;
    if (usedDateTimes.has(dateTime)) {
      return null;
    }
    usedDateTimes.add(dateTime);
    return {
      key: slot.date + slot.start,
      date: slot.date,
      start: slot.start,
      groupedSlots: slot.groupedSlots,
      column: slot.column,
      totalDuration: slot.totalDuration,
    };
  });
  return results.filter(result => result).sort((a, b) => a.key.localeCompare(b.key));
}